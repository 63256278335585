@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

/* Logo Sliding */

.slider {
  height: 250px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(250px * 28);
  animation: scroll 40s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 14));
  }
}

.slide {
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 120%;
}

img {
  width: 100%;
}

/* Image Hover Effect */

img:hover {
  transform: translateZ(20px);
}

/* Cards on Hover */

.contain {
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.contain:hover {
  background-image: linear-gradient(to bottom, #e81663, #e81663);
}

.head h4 {
  transition: all 0.3s ease-in-out;
}

.contain:hover .head span {
  color: #ffffff !important;
}

.icons .icon1 {
  transition: all 0.3s ease-in-out;
}

.contain:hover .icons .icon1 {
  fill: #ffffff !important;
  border-color: white !important;
}

.text {
  transition: all 0.3s ease-in-out;
}

.contain:hover .text {
  color: #ffffff !important;
}

/* Stay Organised heading */

.org:hover .diff {
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.org:hover .apart {
  font-size: 3.75rem /* 60px */;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  font-weight: 600;
}

/* Slide Animation in Connect */

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -8px
    ); /* Adjust this value based on your preference */
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(8px); /* Adjust this value based on your preference */
  }
}

.animate-slideLeft {
  animation: slideLeft 0.5s infinite alternate;
}

.animate-slideRight {
  animation: slideRight 0.5s infinite alternate;
}

.acme-regular {
  font-family: "Acme", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Transition on Brand & Influencer component*/

.fade-in {
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Pop up animation */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  animation: popup-scale 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
    popup-fade-in 0.3s ease-in-out forwards;
  opacity: 0;
  transform: scale(0.5);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.popup-text {
  text-align: center;
}

@keyframes popup-scale {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes popup-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Connect.css */

.transition-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.transition-container.hidden {
  opacity: 0;
}
